import * as React from 'react';
import { ServerTicket, ServerTicketAssignment } from '../TicketWork/TicketWorkManager.constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap';
import { SupportDepartment, TicketCategories, TicketWorkStatuses } from '../TicketWork/TicketTableManager.constants';
import Select from 'react-select';
import { reactSelectBasicStyle } from '../../../style/select-constants';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { CloseTicketStatusModal } from '../TicketModals/ClosingTicketStatusModal';
import { WarningModal, YesNoModal } from '../../CoreComponents/Modals';
import { pullAccountants, pullAdmin, pullHumanResources, pullSecondaryCategories, pullTechSupport, pullVirdianCompliance, pullViridianRegionals } from '../../../functions/fetchLinkedObjects';
import { getUserID } from '../../../functions/authActions';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface TicketAssignentInformationWidgetProps {
  uid: string;
}

interface TicketAssignmentInformationWidgetState {
  uid: string;
  category: SelectOptions;
  categories: Array<SelectOptions>;

  tempStatus: SelectOptions;
  status: SelectOptions;

  department: SelectOptions;

  assignee: SelectOptions;
  assignees: Array<SelectOptions>;
  tempAssignee: SelectOptions;
}

export default class TicketAssignmentInformationWidget extends React.Component<TicketAssignentInformationWidgetProps, TicketAssignmentInformationWidgetState> {

  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  closingTicketStatusNotes = React.createRef<CloseTicketStatusModal>();
  modalRef = React.createRef<YesNoModal>();

  intervalCheck: NodeJS.Timer;

  constructor(props: TicketAssignentInformationWidgetProps) {
    super(props);

    this.state = {
      uid: props.uid,
      category: { label: "None Selected", value: "0" },
      categories: [],
      tempStatus: { label: "Select Status", value: '-1' },
      status: { label: "Open", value: "0" },
      department: { label: "", value: "" },
      assignee: { label: "Select Assignee", value: '-1' },
      assignees: [],
      tempAssignee: { label: "Select Assignee", value: '-1' },
    }
    this.changeStatus = this.changeStatus.bind(this);
    this.showStatusModal = this.showStatusModal.bind(this);
    this.removeStatus = this.removeStatus.bind(this);
    this._changeStatus = this._changeStatus.bind(this);
    this.changeCategory = this.changeCategory.bind(this);
    this.changeDepartment = this.changeDepartment.bind(this);
    this.changeAssignee = this.changeAssignee.bind(this);
    this._changeAssignee = this._changeAssignee.bind(this);
    this.removeAssignee = this.removeAssignee.bind(this);
    this.showAssigneeModal = this.showAssigneeModal.bind(this);
    this.update = this.update.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.update();
    this.intervalCheck = setInterval(this.update, 2500);
  }

  async componentWillUnmount(): Promise<void> {
    clearInterval(this.intervalCheck);
  }

  async update(): Promise<void> {
    const ticket: ServerTicketAssignment = (await axios.get("api/tickets/" + this.state.uid + "/assignment")).data;
    const categories: Array<SelectOptions> = await pullSecondaryCategories();
    this.setState({
      department: SupportDepartment.filter(u => u.value === ticket.support.toString())[0],
      category: categories.filter(u => u.value === ticket.categoryUID)[0],
      categories: categories,
      assignee: { label: ticket.assignee, value: ticket.assigneeUID },
      status: { label: TicketWorkStatuses.filter(u => parseInt(u.value) === ticket.ticketWorkStatus)[0].label, value: ticket.ticketWorkStatus.toString() }
    }, () => this.pullDepartmentEmployees());
  } 

  async changeCategory(event: SelectOptions | null): Promise<void> {
    const data = {
      TicketUID: this.state.uid,
      Category: event.value,
      ModifierUID: getUserID()
    }
    await axios.patch('./api/tickets/category-change', data)
    this.setState({ category: event })
  }

  showAssigneeModal() {
    this.modalRef.current?.show(
      "Are you sure you want to change Assignee?",
      "Change Assignee Confirm",
      () => this.removeAssignee(),
      () => this._changeAssignee(),
    )
  }

  changeAssignee(event: SelectOptions | null): void {
    if (event === null) { return; }
    this.setState({
      tempAssignee: event
    }, this.showAssigneeModal);
  }

  async _changeAssignee(): Promise<void> {
    const data = {
      TicketUID: this.state.uid,
      EmployeeUID: this.state.tempAssignee.value,
      ModifierUID: getUserID()
    }
    await axios.patch('./api/tickets/assignee-change', data)
    this.setState({
      assignee: this.state.tempAssignee,
      tempAssignee: null
    })
  }

  removeAssignee(): void {
    this.setState({ tempAssignee: null })
  }

  async pullDepartmentEmployees(): Promise<void> {
    let assignees: Array<SelectOptions> = [];
    switch (this.state.department.value) {
      case "0":
        assignees = await pullTechSupport();
        break;
      case "1":
      case "5":
        assignees = await pullAccountants();
        break;
      case "2":
        assignees = await pullHumanResources();
        break;
      case "3":
        assignees = await pullVirdianCompliance();
        break;
      case "4":
        assignees = await pullViridianRegionals();
        break;
      case "7":
        assignees = await pullAdmin();
        break;
    }
    this.setState({ assignees: assignees });
  }

  //#region Status Methods
  async changeStatus(): Promise<void> {
    if (this.state.tempStatus.value === '2') {
      // show status closed notes modal
      this.closingTicketStatusNotes.current.show(this.state.uid);
    }
    else {
      const data = {
        TicketUID: this.state.uid,
        NewStatus: this.state.tempStatus.value,
        ModifierUID: getUserID()
      }
      await axios.patch('./api/tickets/status-change', data);
      this.setState({
        status: this.state.tempStatus,
        tempStatus: { label: "Select Status", value: '-1' }
      })
    }
  }

  _changeStatus(event: SelectOptions | null): void {
    if (event === null) { return; }
    if (event.value === "5" || event.value === "6") {
      this.warningModal.current.show("You are attempting to change the status to equipment or project without going through the workflows for that process. Please click the buttons below to change to that type of ticket",
        "Invalid Status Change. ");
      return;
    }
    this.setState({
      tempStatus: event
    }, this.showStatusModal);
  }

  showStatusModal(): void {
    this.modalRef.current?.show(
      "Are you sure you want to end the current step?",
      "Change Status Confirmation",
      () => this.removeStatus(),
      () => this.changeStatus(),
    )
  }

  removeStatus(): void {
    this.setState({ tempStatus: null })
  }
  //#endregion

  callback(): void {
    window.location.reload();
  }


  async changeDepartment(event: SelectOptions | null): Promise<void> {
    const data = {
      TicketUID: this.state.uid,
      SupportDepartment: event.value,
      ModifierUID: getUserID()
    }
    await axios.patch('./api/tickets/department-change', data)
    this.setState({ department: event }, async () => await this.pullDepartmentEmployees())
  }

  render(): JSX.Element {
    return (
      <>
        <WarningModal ref={this.warningModal} />
        <YesNoModal ref={this.modalRef} />
        <CloseTicketStatusModal ref={this.closingTicketStatusNotes} callback={this.callback} />
        {RenderHeader("Ticket Assignment")}
        <Row>
          <Col xs='4'>
            <h5 style={{ marginTop: "5px"}}>Category</h5>
          </Col>
          <Col style={{ textAlign: "left" }}>
            <Select
              value={this.state.category}
              styles={reactSelectBasicStyle}
              options={this.state.categories}
              onChange={this.changeCategory}
              menuPosition="fixed"
            />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col xs='4'>
            <h5 style={{ marginTop: "5px" }}>Status</h5>
          </Col>
          <Col style={{ textAlign: "left" }} >
            <Select
              value={this.state.status}
              styles={reactSelectBasicStyle}
              options={TicketWorkStatuses}
              onChange={this._changeStatus}
              menuPosition="fixed"
              isDisabled={this.state.status.value === "5" || this.state.status.value === "6"}
            />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col xs='4'>
            <h5 style={{ marginTop: "5px" }}>Department</h5>
          </Col>
          <Col style={{ textAlign: "left" }} >
            <Select
              value={this.state.department}
              styles={reactSelectBasicStyle}
              options={SupportDepartment}
              onChange={this.changeDepartment}
              menuPosition="fixed"
            />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col xs='4'>
            <h5 style={{ marginTop: "5px" }}>Assignee</h5>
          </Col>
          <Col style={{ textAlign: "left" }} >
            <Select
              value={this.state.assignee}
              styles={reactSelectBasicStyle}
              options={this.state.assignees}
              onChange={this.changeAssignee}
              menuPosition="fixed"
            />
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            {this.state.status.value !== "5" && this.state.status.value !== "6" && <input type="button" className="standard-input" value="Equipment Request" />}
          </Col>
        </Row>
      </>
    )
  }
}