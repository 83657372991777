import axios from 'axios';
import * as React from 'react';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../CoreComponents/interfaces';
import { WorkLogComment, WorkLogModal } from './TicketModals/TicketWorkLogCommentModal';
import { RenderHeader } from '../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface TicketNotesManagerState {
  uid: string;
  headers: Array<FilterAndSettingField>;
  comments: Array<WorkLogComment>;
}
export default class TicketNotesManager extends React.Component<{ uid: string }, TicketNotesManagerState> {

  logWorkModal: React.RefObject<WorkLogModal> = React.createRef<WorkLogModal>();

  constructor(props) {
    super(props);
    this.state = {
      uid: props.uid,
      headers: [],
      comments: []
    }
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
    let comments = await this.pullTicketComments();
    this.setState({ comments: comments });
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Assignee",
        filter: {},
        setting: true
      },
      {
        columnName: "Comment",
        filter: {},
        setting: true
      },
      {
        columnName: "Est. Time (Minutes)",
        filter: {},
        setting: true
      }
    ]
    this.setState({ headers: headers });
  }

  async pullTicketComments(): Promise<any> {
    let comments: any = [];
    const response = await axios.get("./api/ticket-comments/" + this.state.uid);
    comments = response.data;
    return comments;
  }

  render(): JSX.Element {
    return (
      <>
        <WorkLogModal ref={this.logWorkModal} callback={() => window.location.reload()} />
        {RenderHeader("Ticket Comments")}
        <div style={{ height: "26vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.comments.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.assignee}</td>
                    <td className="fixedTableCell">{item.comment}</td>
                    <td className="fixedTableCell">{item.estimatedTimeInMinutes}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <input type="button" className="standard-input" value="Log Work" onClick={() => this.logWorkModal.current.show(this.state.uid, null)} />
      </>
    )
  }
}
