import * as React from 'react';
import logos from '../../../graphics/all-logos.png';
import { pullProperties } from '../../../functions/fetchLinkedObjects';
import { SelectOptionsProperty } from '../../../interfaces/CoreInterfaces';
import { SavedFileImageData, generateImageGrid } from '../../../functions/imageTools';
import { GetSettings, SetSettingKey, SetSettings } from '../../../functions/StateManagement/StateStorageFunctions';
import { TicketSubmissionSettings } from '../../../reducers/TicketTableSettings';
import { DarkLightMode, TicketSubmissionPageAuthState, TicketSubmissionPageProps } from './TicketSubmissionPage.constants';
import { AuthState, getBearerToken, getUserFirstName, getUserID, getUserLastName } from '../../../functions/authActions';
import { StatusModal, WarningModal } from '../../CoreComponents/Modals';
import imageCompression from 'browser-image-compression';
import axios from 'axios';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { FilterWithPropertyCode } from '../../../functions/selectTools';
import { pullEmployeeEmail } from '../../../functions/fetchObjectNameFunctions';

export default class TicketSubmissionPageAuth extends React.Component<TicketSubmissionPageProps, TicketSubmissionPageAuthState> {

  emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();

  constructor(props: TicketSubmissionPageProps) {
    super(props);
    let settings: TicketSubmissionSettings = GetSettings<TicketSubmissionSettings>("ticketSubmissionSettings");
    if (settings === null) {
      settings = { firstName: "", lastName: "", email: "", phoneNumber: "", property: "" };
      SetSettings("ticketSubmissionSettings", settings);
    }
    let auth: AuthState = GetSettings<AuthState>("userAuthenticationSettings");
    this.state = {
      ticketSubmissionState: settings,
      auth: auth,
      failedSubmit: false,
      failedSubmitText: "Please fill all required fields before submitting. If there is a red border, it's not ready to submit.",
      currentTab: 0,
      submitted: false,
      properties: [],
      propertySelect: { label: "Select Property", value: "", code: "" },
      email: "",
      phone: "",
      firstName: "",
      lastName: "",
      problemSummary: "",
      loading: false,
      submitDisabled: false,
      photos: [],
      photoUIDs: [],
    }
    this.selectProperty = this.selectProperty.bind(this);
    this.changeProblemSummary = this.changeProblemSummary.bind(this);
    this.submit = this.submit.bind(this);
    this.submitAnother = this.submitAnother.bind(this);
    this.addImages = this.addImages.bind(this);
    this.removePhotos = this.removePhotos.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    const allSites: Array<SelectOptionsProperty> = await pullProperties();
    let firstName = await getUserFirstName();
    let lastName = await getUserLastName();
    let email = await pullEmployeeEmail(getUserID());
    let propertySelect = { label: "Select Property", value: "", code: "" }
    if (this.state.ticketSubmissionState.property !== "") {
      propertySelect = allSites.filter(u => u.value === this.state.ticketSubmissionState.property)[0];
    }
    this.setState({
      firstName: firstName,
      lastName: lastName,
      email: email,
      properties: allSites,
      propertySelect: propertySelect
    });
  }

  async componentWillUnmount(): Promise<void> {
    // Need to clean up the saved files.
  }

  selectProperty(event: SelectOptionsProperty | null): void {
    if (event === null) { return; }
    this.setState({ propertySelect: event })
    const ticketState = this.state.ticketSubmissionState;
    ticketState.property = event.value;
    SetSettingKey("ticketSubmissionSettings", "property", event.value);
    this.setState({
      ticketSubmissionState: ticketState
    })
  }

  changeProblemSummary(event: React.ChangeEvent<HTMLTextAreaElement>): void {
    this.setState({
      problemSummary: event.target.value
    })
  }

  async addImages(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
    this.statusModal.current.display("Uploading Photos, Please Wait.", "Please wait while all photos are uploaded.")
    const files = event.target.files;
    for (let i = 0; i < files.length; ++i) {
      let file = files.item(i);
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      const image = await imageCompression(file, options);
      const formData = new FormData();
      formData.append("File", image);
      let response = await axios.post("/api/saved-files/image/ticket", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 202) {
        const imageID = response.data;
        this.state.photoUIDs.push(imageID)
        this.setState({
          loading: false
        })
      }
    }
    await this.getPhotos();
    this.statusModal.current.hide();
  }

  async removePhotos(uid: string): Promise<void> {
    this.statusModal.current.display("Removing Photo, Please Wait.", "Please wait while photos are changed");
    let photos = this.state.photoUIDs;
    let idx = photos.indexOf(uid);
    photos.splice(idx, 1);
    let response = await axios.delete('./api/saved-files/' + uid);
    if (response.status !== 202) {
      this.warningModal.current.show("There was an issue deleting the photo, please let the tech team know this has happenened", "Error.");
    }
    else {
      await this.getPhotos();
      this.statusModal.current.hide();
    }
  }

  async getPhotos(): Promise<void> {
    const photos: Array<SavedFileImageData> = [];
    await this.state.photoUIDs.forEach(async (imgUID) => {
      let response = await axios.get('./api/saved-files/' + imgUID + "/pull", { responseType: "blob" });
      if (response.status === 200) {
        if (response.data.type.includes("image")) {
          const img = URL.createObjectURL(response.data);
          photos.push({
            img: img,
            imgUID: imgUID
          })
          this.setState({ photos: photos, loading: false })
        }
      }
    })
  }

  async submit(): Promise<void> {

    this.setState({ submitDisabled: true })

    if (this.state.problemSummary === '') {
      this.setState({
        failedSubmit: true,
        submitDisabled: false,
        failedSubmitText: "Please enter a description"
      });
      return;
    }

    if (this.state.propertySelect.value === '' || this.state.ticketSubmissionState.property === "") {
      this.setState({
        failedSubmit: true,
        submitDisabled: false,
        failedSubmitText: "In order to submit a ticket you need to select a property. Please do so before proceeding."
      });
      return;
    }

    const data = {
      PropertyUID: this.state.ticketSubmissionState.property,
      EmployeeUID: await getUserID(),
      ProblemSummary: this.state.problemSummary,
    }
    this.statusModal.current.display("Submitting Ticket.", "Please wait for a minute while we submit the ticket & link the photos to it");
    let response = await axios.post("./api/tickets/auth", data, { validateStatus: () => true });
    if (response.status === 202) {
      this.setState({
        submitted: true,
        failedSubmit: true,
        failedSubmitText: ""
      });
      for (var photo in this.state.photos) {
        const data = {
          TicketUID: response.data,
          SavedFileUID: this.state.photos[photo].imgUID
        };
        await axios.post('./api/tech-ticket-photo-map', data)
      }
      this.statusModal.current.hide();
    }
    else {
      this.setState({
        failedSubmit: true,
        submitDisabled: false,
        failedSubmitText: "We are having trouble verifying your information. Please ensure your email is correct and you have selected your property"
      })
    }
  }

  submitAnother(): void {
    this.setState({
      submitted: false,
      submitDisabled: false,
      photoUIDs: [],
      photos: []
    })
  }

  render(): JSX.Element {
    return (
      <div className="ticket-submission-form">
        <StatusModal ref={this.statusModal} />
        <WarningModal ref={this.warningModal} />
        <div style={{ marginLeft: "25vw", marginRight: "25vw", overflowX: "hidden", overflowY: "auto" }}>
          <Row>
            <Col>
              <h3 style={{ marginTop: "5vh" }} className="ticket-text-heading">Support Request</h3>
              <h4 className="ticket-text-heading required-header">
                {this.state.failedSubmit && this.state.failedSubmitText}
              </h4>
              <h6 style={{ textAlign: "center" }} >
                {this.state.currentTab === 0 && <a className="ticket-text" href="https://docs.google.com/document/d/1FCS6kwnAtHoxsCo_yyivEuBOwlDwetfm6WBTTFqeCQU/edit#"
                  target="_blank" rel="noopener noreferrer"
                >How To Document</a>}
                {this.state.currentTab === 1 && <a className="ticket-text" href="https://docs.google.com/document/d/e/2PACX-1vT95iUZVFsQQHgtaNlS_7FqqSdMKerjFVawrKKLca5YEGz93nQi2bygeWTWSxfnPjRzRFbAhmnAsBaq/pub"
                  target="_blank" rel="noopener noreferrer"
                >How To Document</a>}
              </h6>
              <img src={logos} style={{ width: "100%", height: "8em", paddingTop: "2em" }} />
            </Col>
          </Row>
          <hr />
          {!this.state.submitted && <>
            <Row>
              <Col>
                <h4 className="ticket-text-title">Contact Information</h4>
                <h6 className="ticket-text">Please fill out the fields below so that we may be able to contact you</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5 className="ticket-text">First Name</h5>
                <p className="ticket-text">{this.state.firstName}</p>
              </Col>
              <Col>
                <h5 className="ticket-text">Last Name</h5>
                <p className="ticket-text">{this.state.lastName}</p>
              </Col>
              <Col>
                <h5 className="ticket-text">Property</h5>
                <Select
                  value={this.state.propertySelect}
                  styles={DarkLightMode()}
                  options={this.state.properties}
                  onChange={this.selectProperty}
                  menuPlacement="bottom"
                  required={(this.state.propertySelect?.value ?? "") === ''}
                  filterOption={FilterWithPropertyCode}
                />
              </Col>
              <Col>
                <h5 className="ticket-text">Email</h5>
                <p className="ticket-text">{this.state.email}</p>
              </Col>
              <Col>
                <h5 className="ticket-text">Phone Number</h5>
                <p className="ticket-text">{this.state.phone}</p>
              </Col>

            </Row>
            <hr />
            <Row>
              <Col>
                <h5 className="ticket-text">Description</h5>
                <textarea className="ticket-standard-input" value={this.state.problemSummary} onChange={this.changeProblemSummary}
                  style={{ minHeight: "20vh" }}
                  placeholder="Required" required={this.state.problemSummary === ''}
                />
              </Col>
              {this.state.currentTab === 0 &&
                <Col>
                  <h5 className="ticket-text" style={{ marginLeft: "-2%" }}>Add Screenshot(s) <em style={{ fontSize: "9px", verticalAlign: 'center' }}> (JPG or PNG only)</em></h5>
                  <Row>
                    <input type="file" onChange={this.addImages} id="ticket-picture" accept="image/png, image/gif, image/jpeg" className="ticket-standard-input" style={{ border: "solid", height: "20vh", paddingLeft: "7vw", paddingTop: "7vh", width: "95%", marginBottom: "2vh" }} multiple />
                  </Row>
                </Col>
              }
            </Row>
            <hr />
            <Row>
              <Col>
                {!this.state.submitDisabled && !this.state.loading && <input type="button" style={{ height: "5vh" }} className="ticket-submit-button" value="Submit Request" onClick={this.submit} />}
              </Col>
              <Col>
                <input type="button" style={{ height: "5vh" }} className="ticket-submit-button" value="Change Account" onClick={() => window.location.assign("/logout?")} />
              </Col>
            </Row>
            <br />
            <br />
            {
              !this.state.loading && generateImageGrid(this.state.photos, 4, null, this.removePhotos)
            }
          </>
          }
          {this.state.submitted && <>
            <h3 className="ticket-text-heading" style={{ width: "100%", textAlign: "center", paddingTop: "2vh" }}>Success!</h3>
            <h5 className="ticket-text-heading" style={{ width: "100%", textAlign: "center", paddingTop: "1vh" }}>Your support request has been submitted. You should receive a confirmation email soon, and we will be in contact shortly.</h5>
            <h5 className="ticket-text-heading" style={{ width: "100%", textAlign: "center", paddingTop: "1vh" }}>You may now close this window.</h5>
            <h3 className="ticket-text-heading" style={{ width: "100%", textAlign: "center", paddingTop: "1vh", marginBottom: "3vh" }}>Thank you!</h3>
            <hr />
            <Row>
              <Col>
                <input type="button" style={{ height: "5vh" }} className="ticket-submit-button" value="Submit Another Ticket" onClick={this.submitAnother} />
              </Col>
              <Col>
                <input type="button" style={{ height: "5vh" }} className="ticket-submit-button" value="Return to Home" onClick={() => window.location.assign("/")} />
              </Col>
            </Row>
          </>}
        </div>
      </div>
    )
  }
}
