import * as React from 'react';
import DataEntryModal, { DataEntryModalField, DataEntryModalFieldCheckbox, DataEntryModalFieldFunction, DataEntryModalFieldSelect, DataEntryModalFieldText } from '../../CoreComponents/DataEntryModal';
import { pullDrawsBySite, pullExternalCompaniesABCHASE, pullManagedProjectsByJob } from '../../../functions/fetchLinkedObjects';
import { SelectOptions } from '../../../interfaces/CoreInterfaces';
import { getUserID } from '../../../functions/authActions';
import axios from 'axios';

export interface AddSubcontractorToDrawModalData {
  uid: string;
  subcontractor: SelectOptions;
  site: SelectOptions;
  draw: SelectOptions;
  grossAmount: number;
  netAmount: number;
  isFinal: boolean;
  isPaid: boolean;
  conditionalStatus: number;
  unconditionalStatus: number;
}

interface AddSubcontractorToDrawModalProps {
  uid: string;
  oldSystem: boolean;
}

interface AddSubcontractorToDrawModalState {
  draws: Array<SelectOptions>;
  subcontractors: Array<SelectOptions>;
  data: AddSubcontractorToDrawModalData;
  site: SelectOptions;
  sites: Array<SelectOptions>;
  isDefault: boolean;
  previousController: AbortController;
}

export default class AddSubcontractorToDrawModal extends React.Component<AddSubcontractorToDrawModalProps, AddSubcontractorToDrawModalState> {

  entryModal: React.RefObject<DataEntryModal> = React.createRef<DataEntryModal>();

  static DefaultData: AddSubcontractorToDrawModalData = {
    uid: "",
    subcontractor: { label: "Select Subcontractor", value: "" },
    grossAmount: 0,
    netAmount: 0,
    draw: { label: "Select Draw", value: "" },
    site: { label: "Select Site", value: "" },
    isFinal: false,
    isPaid: false,
    conditionalStatus: -5,
    unconditionalStatus: -5
  };

  constructor(props: AddSubcontractorToDrawModalProps) {
    super(props);
    this.state = {
      site: { label: "Select Site", value: "" },
      data: AddSubcontractorToDrawModal.DefaultData,
      draws: [],
      sites: [],
      subcontractors: [],
      isDefault: true,
      previousController: null
    }
    this.generateData = this.generateData.bind(this);
    this.changeSite = this.changeSite.bind(this);
    this.sendConditional = this.sendConditional.bind(this);
    this.cancelConditional = this.cancelConditional.bind(this);
    this.sendUnconditional = this.sendUnconditional.bind(this);
    this.cancelUnconditional = this.cancelUnconditional.bind(this);
    this.updateIsPaid = this.updateIsPaid.bind(this);
    this.updateNet = this.updateNet.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const contractors = await pullExternalCompaniesABCHASE();
    const sites = await pullManagedProjectsByJob(this.props.uid);
    this.setState({ subcontractors: contractors, sites: sites });
  }

  async show(data: AddSubcontractorToDrawModalData | null, site: SelectOptions, draw: SelectOptions): Promise<void> {
    if (data === null) {
      let data = AddSubcontractorToDrawModal.DefaultData;
      data.site = site;
      data.draw = draw;
      this.setState({
        data: data,
        draws: [],
        isDefault: true,
      }, async () => await this.generateData());
    }
    else {
      let draws = await pullDrawsBySite(data.site.value);
      this.setState({
        data: data,
        draws: draws,
        isDefault: false
      }, async () => await this.generateData());
    }
  }

  async changeSite(event: SelectOptions) {
    let data = this.state.data;
    data.site = event;
    let draws = await pullDrawsBySite(event.value);
    this.setState({ draws: draws, data: data }, () => this.generateData());
  }

  async sendConditional(): Promise<void> {
    let data = { subcontractorUID: this.state.data.uid, createdByUID: getUserID(), isConditional: true };
    let response = await axios.post('./api/job-waiver-tracking/start-waiver', data, { validateStatus: () => true });
    if (response.status === 202) {
      let _data = this.state.data;
      _data.conditionalStatus === 0
      this.setState({ data: _data }, () => this.generateData());
    }
    else {
      // TODO -  Handle Error
      console.error("Error sending the conditional waiver");
    }
  }

  async cancelConditional(): Promise<void> {
    let data = { subcontractorUID: this.state.data.uid, isConditional: true };
    let response = await axios.post('./api/job-waiver-tracking/recall', data, { validateStatus: () => true });
    if (response.status === 202) {
      let _data = this.state.data;
      _data.conditionalStatus === -5
      this.setState({ data: _data }, () => this.generateData());
    }
  }

  async sendUnconditional(): Promise<void> {
    let data = { subcontractorUID: this.state.data.uid, createdByUID: getUserID(), isConditional: false };
    let response = await axios.post('./api/job-waiver-tracking/start-waiver', data, { validateStatus: () => true });
    if (response.status === 202) {
      let _data = this.state.data;
      _data.unconditionalStatus === 0
      this.setState({ data: _data }, () => this.generateData());
    }
    else {
      // TODO -  Handle Error
      console.error("Error sending the conditional waiver");
    }
  }

  async cancelUnconditional(): Promise<void> {
    let data = { subcontractorUID: this.state.data.uid, isConditional: false };
    let response = await axios.post('./api/job-waiver-tracking/recall', data, { validateStatus: () => true });
    if (response.status === 202) {
      let _data = this.state.data;
      _data.unconditionalStatus === -5
      this.setState({ data: _data }, () => this.generateData());
    }
  }

  updateIsPaid(): void {
    let data = this.state.data;
    data.isPaid = !data.isPaid;
    this.setState({ data: data }, () => this.generateData());
  }

  async generateConditionalFunction(): Promise<any> {
    let _return: any = {
      conditional: { function: () => console.error("Default Error Called"), value: null, disabled: true },
      unconditional: { function: () => console.error("Default Error Called"), value: null, disabled: true }
    }
    if (this.state.data.conditionalStatus === -5 || this.state.data.conditionalStatus === 2) {
      _return.conditional = { function: this.sendConditional, value: "Send", disabled: false };
      _return.unconditional.disabled = true;
      _return.unconditional.value = "Conditional Required";
    }
    else if (this.state.data.conditionalStatus === 0) {
      _return.conditional = { function: this.cancelConditional, value: "Recall", disabled: false };
      _return.unconditional.disabled = true;
      _return.unconditional.value = "Conditional Needs Signature";
    }
    else if (this.state.data.conditionalStatus === 1 && !this.state.data.isPaid) {
      _return.conditional = { function: () => console.error("Default Error Called"), value: "No Action Needed", disabled: true };
      _return.unconditional = { function: () => console.error("Default Error Called"), value: "Must Be Paid", disabled: true }
    }
    else if (this.state.data.conditionalStatus === 1 && this.state.data.isPaid && (this.state.data.unconditionalStatus === -5 || this.state.data.unconditionalStatus === 2)) {
      _return.unconditional = { function: this.sendUnconditional, value: "Send", disabled: false };
      _return.conditional.disabled = true;
      _return.conditional.value = "No Action Needed";
    }
    else if (this.state.data.conditionalStatus === 1 && this.state.data.isPaid && this.state.data.unconditionalStatus === 0) {
      _return.unconditional = { function: this.cancelUnconditional, value: "Recall", disabled: false };
      _return.conditional.disabled = true;
      _return.conditional.value = "No Action Needed";
    }
    return _return;
  }

  async updateNet(event: React.ChangeEvent<HTMLInputElement>, field: Array<DataEntryModalField>): Promise<void> {
    let net = parseFloat(event.target.value) * .95;
    net = Math.round(net * 100) / 100;
    let data = this.state.data;
    data.netAmount = net;
    data.grossAmount = parseFloat(event.target.value);
    data.draw = (field.filter(u => u.key === "drawRequestUID")[0] as DataEntryModalFieldSelect).value as SelectOptions;
    data.site = (field.filter(u => u.key === "siteUID")[0] as DataEntryModalFieldSelect).value as SelectOptions;
    data.subcontractor = (field.filter(u => u.key === "subcontractorUID")[0] as DataEntryModalFieldSelect).value as SelectOptions;
    data.isPaid = (field.filter(u => u.key === "isPaid")[0] as DataEntryModalFieldCheckbox).checkbox;
    data.isFinal = (field.filter(u => u.key === "isFinalWaiver")[0] as DataEntryModalFieldCheckbox).checkbox;
    this.setState({ data: data }, () => this.generateData());
    let _data = { uid: this.state.data.uid, value: parseFloat(event.target.value) };
    let previousController = new AbortController();
    if (this.state.previousController !== null) { this.state.previousController.abort(); }
    this.setState({ previousController: previousController })
    let response = await axios.patch('./api/subcontractor-setup/patch-gross-amount', _data, { signal: previousController.signal});
    if (response.status !== 202) {
      // TODO - Handle Error
    }
    previousController = new AbortController();
    if (this.state.previousController !== null) { this.state.previousController.abort(); }
    this.setState({ previousController: previousController })
    _data = { uid: this.state.data.uid, value: net };
    response = await axios.patch('./api/subcontractor-setup/patch-net-amount', _data, { signal: previousController.signal});
    if (response.status !== 202) {
      // TODO - Handle Error
    }
  }

  async deleteSubcontractor(uid: string) {
    let response = await axios.delete('./api/subcontractor-setup/' + uid);
    if (response.status !== 202) {
      // TODO: Throw Error
    }
    else {
      window.location.reload();
    }
  }

  async generateData(): Promise<void> {
    let data: Array<DataEntryModalField> = [];
    this.entryModal.current.hide();
    data.push({ key: "siteUID", name: "Site", patchUrl: undefined, value: this.state.data.site, options: this.state.sites, isMulti: false, callback: this.changeSite, isDisabled: !this.state.isDefault } as DataEntryModalFieldSelect);
    if (this.state.data.site.value !== "") {
      data.push({ key: "drawRequestUID", name: "Draw", patchUrl: undefined, value: this.state.data.draw, options: this.state.draws, isMulti: false, isDisabled: !this.state.isDefault } as DataEntryModalFieldSelect);
      data.push({ key: "subcontractorUID", name: "Subcontractor", patchUrl: undefined, value: this.state.data.subcontractor, options: this.state.subcontractors, isMulti: false, isDisabled: !this.state.isDefault } as DataEntryModalFieldSelect);
      data.push({
        key: "grossAmount", name: "Gross Amount", patchUrl: undefined, type: "number", value: this.state.data.grossAmount, placeholder: "0.00", callback: this.updateNet
      } as DataEntryModalFieldText);
      data.push({ key: "netAmount", name: "Net Amount", patchUrl: './api/subcontractor-setup/patch-net-amount', type: "number", value: this.state.data.netAmount, placeholder: "0.00" } as DataEntryModalFieldText);
      data.push({ key: "isFinalWaiver", name: "Is Final", patchUrl: './api/subcontractor-setup/is-final', checkbox: this.state.data.isFinal } as DataEntryModalFieldCheckbox);
      data.push({ key: "isPaid", name: "Is Paid", patchUrl: './api/subcontractor-setup/is-paid', checkbox: this.state.data.isPaid, callback: this.updateIsPaid } as DataEntryModalFieldCheckbox);
      if (!this.state.isDefault && !this.props.oldSystem) {
        let waiverSettings = await this.generateConditionalFunction();
        data.push({ key: "conditional", name: "Conditional", patchUrl: undefined, value: waiverSettings.conditional.value, function: waiverSettings.conditional.function, disabled: waiverSettings.conditional.disabled } as DataEntryModalFieldFunction);
        data.push({ key: "unconditional", name: "Unconditional", patchUrl: undefined, value: waiverSettings.unconditional.value, function: waiverSettings.unconditional.function, disabled: waiverSettings.unconditional.disabled } as DataEntryModalFieldFunction);
      }
      if (this.state.data.conditionalStatus === -5 || this.state.data.conditionalStatus === 2 || this.state.data.grossAmount === 0 || this.state.data.netAmount === 0) {
        data.push({ key: "delete", name: "Delete", patchUrl: undefined, value: "Delete", function: () => this.deleteSubcontractor(this.state.data.uid), disabled: false } as DataEntryModalFieldFunction);
      }
    }
    this.entryModal.current.update(data);
    this.entryModal.current.show("Manage Subcontractor", this.state.data.uid, 'api/subcontractor-setup');
  }

  render(): JSX.Element {
    return (
      <>
        <DataEntryModal ref={this.entryModal} callback={() => window.location.reload()} />
      </>
    )
  }
}