import axios from "axios";
import * as React from "react"
import { Col, Row } from "reactstrap";
import { getUserID } from "../../../functions/authActions";
import { numberWithCommas } from "../../../functions/numberFunctions";
import { WarningModal } from "../../CoreComponents/Modals";

interface SubcontractingProjectSnapshotData {
  name: string;
  uid: string;
  totalCompleted: number;
  totalScheduled: number;
}

interface WorkflowReadyForReviewState {
  projectData: Array<SubcontractingProjectSnapshotData>;
  isPresident: boolean;
  useBoth: boolean;
  hideValues: boolean;
}

interface WorkflowReadyForReviewProps {
  isPresident: boolean;
  useBoth: boolean;
  hideValues: boolean;
}


export class GeneralContractingProjectSnapshotWidget extends React.Component<WorkflowReadyForReviewProps, WorkflowReadyForReviewState> {

  warningModal = React.createRef<WarningModal>();
  timer: NodeJS.Timer = null;

  constructor(props) {
    super(props);
    this.state = {
      isPresident: props.isPresident,
      projectData: [],
      useBoth: props.useBoth,
      hideValues: props.hideValues
    }
  }

  async componentDidMount(): Promise<void> {
    let response = await axios.get( './api/managed-job-projects/all-project-snapshot/' + (!this.state.isPresident ? getUserID() + "/" : "") + (this.state.useBoth ? "NA" : "Viridian Construction LLC") + "/" + (this.state.useBoth),
      { validateStatus: () => true }
    );
    this.setState({
      projectData: response.data
    })
  }

  render(): JSX.Element {
    return (
      <div>
        <div style={{ maxHeight: "38vh", minHeight: "38vh", overflowY: "auto", overflowX: "hidden", borderRadius: "10% 10% 0 0", backgroundColor: "#4C4A42" }}>
          <h4 style={{ width: "100%", textAlign: "center", fontSize: "14px", marginTop: "1vh" }}>General Contracting Completion Snapshot</h4>
          <WarningModal ref={this.warningModal} />
          {
            this.state.projectData && this.state.projectData.map((item, idx) => (
              <div key={idx}>
                <hr style={{ height: "4px", backgroundColor: "#ddd" }} />
                <div style={{ width: "100%" }}>
                  <Row>
                    <Col>
                      <h5 style={{ width: "96%", marginLeft: "2%", marginTop: "1%", fontSize: "12px" }}>{item.name}</h5>
                    </Col>
                    <Col>
                      <h6 style={{ width: "100%", textAlign: "right", marginTop: "1%", marginRight: "3%", fontSize: "12px" }}>{numberWithCommas((item.totalCompleted / item.totalScheduled) * 100, 2)}%</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <progress className={"progress-bar"} value={item.totalCompleted} max={item.totalScheduled}
                        style={{ width: "98%", marginLeft: "1%" }}>
                      </progress>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6 style={{ width: "100%", marginLeft: "1%", marginTop: "1%", fontSize: "12px" }}>
                        {
                          this.state.hideValues ?
                          `${numberWithCommas((item.totalCompleted / item.totalScheduled) * 100, 2)}% of the project has been completed`
                          :
                          `$${numberWithCommas(item.totalCompleted)} of the $${numberWithCommas(item.totalScheduled)} has been completed`
                        }
                      </h6>
                    </Col>
                  </Row>
                </div>
              </div>

            ))
          }
        </div>
      </div>
    )
  }
}