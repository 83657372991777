import { faCog, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import * as React from "react";
import { EMPTY_GUID } from "../../constants/DefaultConstants";
import { CreateGlobalAlert } from "../../functions/CreateGlobalAlerts";
import FilteredWithSettingsTableHeader from "../CoreComponents/CoreTableHeaders";
import { TableAdditionRow } from "../CoreComponents/CoreTableRows";
import { DatePicker } from "../CoreComponents/DateComponents";
import { FilterAndSettingField } from "../CoreComponents/interfaces";
import { StatusModal } from "../CoreComponents/Modals";
import { PropertyLicenseManagementTable } from "./PropertyLicenseManagementTable";
import { getBearerToken } from "../../functions/authActions";

interface UnitLicenseData {
  uid: string;
  softwareUid: string;
  name: string;
  costPerUnit: number;
  usedUnits: number;
  totalUnits: number;
  contractDate: Date;
  nextContractRenewal: Date;
  isAnnual: boolean;
  notes: string;
}

interface UnitLicenseTableRowProps {
  row: UnitLicenseData;
  index: number;
  edit: (index: number) => void;
  update: (uid: string) => void;
}

interface UnitLicenseManagementProps {
  softwareUid: string;
}

interface UnitLicenseManagementState {
  headers: Array<FilterAndSettingField>;
  rows: Array<UnitLicenseData>;
  currentIndex: number;
  currentUID: string;
  softwareUid: string;
}

interface EditableUnitLicenseProps {
  data: UnitLicenseData;
  save: (data: UnitLicenseData, index: number) => void;
  softwareUid: string;
  index: number;
}

interface EditableUnitLicenseState {
  data: UnitLicenseData;
}


class UnitLicenseTableRow extends React.Component<UnitLicenseTableRowProps, { color: string; }> {

  constructor(props) {
    super(props);
    this.state = {
      color: '#d7c7a7'
    }
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst" onClick={() => this.props.update(this.props.row.uid)}
          style={{ color: this.state.color, cursor: "pointer" }}
          onMouseEnter={() => this.setState({ color: '#b89961' })} onMouseLeave={() => this.setState({ color: '#d7c7a7' })}
        >
          {this.props.row.name}
        </td>
        <td className="fixedTableCell">{this.props.row.costPerUnit}</td>
        <td className="fixedTableCell">{this.props.row.totalUnits}</td>
        <td className="fixedTableCell">{this.props.row.usedUnits}</td>
        <td className="fixedTableCell">{new Date(this.props.row.contractDate).toLocaleDateString()}</td>
        <td className="fixedTableCell">{new Date(this.props.row.nextContractRenewal).toLocaleDateString()}</td>
        <td className="fixedTableCell">{this.props.row.isAnnual ? "Annual" : "Monthly"}</td>
        <td className="fixedTableCell">{this.props.row.notes}</td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faCog} onClick={() => this.props.edit(this.props.index)} style={{ marginLeft: "5%" }} />
        </td>
      </tr >
    )
  }
}

class EditableUnitLicense extends React.Component<EditableUnitLicenseProps, EditableUnitLicenseState>{

  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    }

    this.changeName = this.changeName.bind(this);
    this.changeCostPerUnit = this.changeCostPerUnit.bind(this);
    this.changeTotalUnits = this.changeTotalUnits.bind(this);
    this.changeContractDate = this.changeContractDate.bind(this);
    this.changeNextContractDate = this.changeNextContractDate.bind(this);
    this.changeIsAnnual = this.changeIsAnnual.bind(this);
    this.changeNotes = this.changeNotes.bind(this);

    this.updateName = this.updateName.bind(this);
    this.updateCostPerUnit = this.updateCostPerUnit.bind(this);
    this.updateTotalUnits = this.updateTotalUnits.bind(this);
    this.updateContractDate = this.updateContractDate.bind(this);
    this.updateNextContractDate = this.updateNextContractDate.bind(this);
    this.updateIsAnnual = this.updateIsAnnual.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
  }

  //#region Name Functions
  changeName(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.name = event.target.value;
    this.setState({
      data: data
    });
  }

  async updateName(): Promise<void> {
    if (this.state.data.uid === "") { return; }
    const data = {
      uid: this.state.data.uid,
      name: this.state.data.name
    };
    await axios.patch("./api/unit-license/name", data);
  }
  //#endregion

  //#region Cost Per Unit Functions
  changeCostPerUnit(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.costPerUnit = parseFloat(event.target.value);
    this.setState({
      data: data
    });
  }

  async updateCostPerUnit(): Promise<void> {
    if (this.state.data.uid === "") { return; }
    const data = {
      uid: this.state.data.uid,
      cost: this.state.data.costPerUnit
    };
    await axios.patch("./api/unit-license/unit-cost", data);
  }
  //#endregion

  //#region Base Cost Functions
  changeTotalUnits(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.totalUnits = parseFloat(event.target.value);
    this.setState({
      data: data
    });
  }

  async updateTotalUnits(): Promise<void> {
    if (this.state.data.uid === "") { return; }
    const data = {
      uid: this.state.data.uid,
      totalUnits: this.state.data.totalUnits
    };
    await axios.patch("./api/unit-license/total-units", data);
  }
  //#endregion

  //#region Contract Date
  changeContractDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.state.data.contractDate = new Date(event.target.valueAsDate);
    this.setState({});
  }

  async updateContractDate(): Promise<void> {
    if (this.state.data.uid === "") { return; }
    const data = {
      uid: this.state.data.uid,
      contractDate: this.state.data.contractDate.toISOString()
    };
    await axios.patch("./api/unit-license/contract", data);
  }
  //#endregion

  //#region Next Contract Date
  changeNextContractDate(event: React.ChangeEvent<HTMLInputElement>): void {
    this.state.data.nextContractRenewal = new Date(event.target.valueAsDate);
    this.setState({});
  }

  async updateNextContractDate(): Promise<void> {
    if (this.state.data.uid === "") { return; }
    const data = {
      uid: this.state.data.uid,
      nextContractDate: this.state.data.nextContractRenewal.toISOString()
    };
    await axios.patch("./api/unit-license/next-contract", data);
  }
  //#endregion

  //#region Notes
  changeNotes(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.notes = event.target.value;
    this.setState({
      data: data
    });
  }

  async updateNotes(): Promise<void> {
    if (this.state.data.uid === "") { return; }
    const data = {
      uid: this.state.data.uid,
      notes: this.state.data.notes
    };
    await axios.patch("./api/unit-license/notes", data);
  }
  //#endregion

  //#region Is Annual
  changeIsAnnual(event: React.ChangeEvent<HTMLInputElement>): void {
    let data = this.state.data;
    data.isAnnual = event.target.checked;
    this.setState({
      data: data
    }, () => this.updateIsAnnual());
  }

  async updateIsAnnual(): Promise<void> {
    if (this.state.data.uid === "") { return; }
    const data = {
      uid: this.state.data.uid,
      isAnnual: this.state.data.isAnnual
    };
    await axios.patch("./api/unit-license/is-annual", data);
  }
  //#endregion

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">
          <input type="text" placeholder="Software Name" className="standard-input" value={this.state.data.name} onChange={this.changeName} onBlur={this.updateName} />
        </td>
        <td className="fixedTableCell">
          <input type="number" className="standard-input" value={this.state.data.costPerUnit} onChange={this.changeCostPerUnit} onBlur={this.updateCostPerUnit} />
        </td>
        <td className="fixedTableCell">
          <input type="number" className="standard-input" value={this.state.data.totalUnits} onChange={this.changeTotalUnits} onBlur={this.updateTotalUnits} />
        </td>
        <td className="fixedTableCell">
          Used Units is Calculated
        </td>
        <td className="fixedTableCell">
          <DatePicker className="standard-input" onChange={this.changeContractDate} value={this.state.data.contractDate} onBlur={this.updateContractDate} />
        </td>
        <td className="fixedTableCell">
          <DatePicker className="standard-input" onChange={this.changeNextContractDate} value={this.state.data.nextContractRenewal} onBlur={this.updateNextContractDate} />
        </td>
        <td className="fixedTableCell">
          <input type="checkbox" checked={this.state.data.isAnnual} style={{ marginLeft: "35%" }} onChange={this.changeIsAnnual} />
        </td>
        <td className="fixedTableCell">
          <input type="text" className="standard-input" value={this.state.data.notes} onChange={this.changeNotes} onBlur={this.updateNotes} />
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faSave} onClick={() => this.props.save(this.state.data, this.props.index)} />
        </td>
      </tr>
    )
  }
}

export class UnitLicenseManagement extends React.Component<UnitLicenseManagementProps, UnitLicenseManagementState> {

  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();

  constructor(props) {
    super(props);
    this.state = {
      headers: [],
      rows: [],
      currentIndex: -1,
      currentUID: "",
      softwareUid: props.softwareUid
    }

    this.add = this.add.bind(this);
    this.edit = this.edit.bind(this);
    this.update = this.update.bind(this);
    this.save = this.save.bind(this);
    this.generateETL = this.generateETL.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.generateHeaders();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get('./api/unit-license/software/' + this.state.softwareUid).then((response) => {
      this.setState({
        rows: response.data
      })
    })
  }

  generateHeaders(): void {
    let headers: Array<FilterAndSettingField> = [
      {
        columnName: "Description",
        setting: true,
        filter: {}
      },
      {
        columnName: "Cost Per Unit",
        setting: true,
        filter: {}
      },
      {
        columnName: "Total Units",
        setting: true,
        filter: {}
      },
      {
        columnName: "Used Units",
        setting: true,
        filter: {}
      },
      {
        columnName: "Contract Date",
        setting: true,
        filter: {}
      },
      {
        columnName: "Renewal Date",
        setting: true,
        filter: {}
      },
      {
        columnName: "Billing",
        setting: true,
        filter: {}
      },
      {
        columnName: "Notes",
        setting: true,
        filter: {}
      },
      {
        columnName: "",
        setting: true,
        additionalClasses: "icon",
        filter: {}
      },
    ]
    this.setState({
      headers: headers
    })
  }

  add(): void {
    let rows: Array<UnitLicenseData> = this.state.rows;
    rows.push({
      uid: "",
      softwareUid: "",
      name: "",
      contractDate: new Date(),
      nextContractRenewal: new Date(),
      costPerUnit: 0,
      totalUnits: 0,
      usedUnits: 0,
      notes: "",
      isAnnual: true
    });
    this.setState({
      currentIndex: rows.length - 1,
      rows: rows
    })
  }

  edit(index: number): void {
    this.setState({
      currentIndex: index
    })
  }

  update(uid: string): void {
    this.setState({
      currentUID: uid
    })
  }

  async save(data: UnitLicenseData, index: number): Promise<void> {
    //we patch the edits, so this is only for create
    if (data.uid !== "") {
      this.setState({
        currentIndex: -1,
      })
      CreateGlobalAlert("The Unit License has been updated");
      return;
    }
    data.uid = EMPTY_GUID;
    data.softwareUid = this.state.softwareUid;
    await axios.post('./api/unit-license', data).then((response) => {
      CreateGlobalAlert("Unit License Created");
      let rows = this.state.rows;
      rows[index] = response.data;
      this.setState({
        currentIndex: -1,
        rows: rows
      });
    })
  }

  async generateETL(): Promise<void> {
    const formData = new FormData();
    let key = await axios.get('./api/status-manager');
    this.statusModal.current.display("Generating ETL Export", "Please wait. This can take a minute due to the amount of properties we manage.", key.data)
    let response = await axios.post('./api/unit-license/generate-upload-software/' + this.state.softwareUid + "/" + key.data, formData, { validateStatus: () => true, responseType: "blob" });
    this.statusModal.current.hide();
    if (response.status === 200) {
      const FileDownload = require('js-file-download');
      const content = response.headers['content-disposition'];
      const startIndex = content.lastIndexOf('\'\'') + 2;
      const name = content.substring(startIndex, content.length);
      FileDownload(response.data, decodeURI(name));
    }
    else {
      // need to handle error
    }
  }


  render(): JSX.Element {
    return (
      <div>
        {this.state.currentUID === "" ?
          <>
            <StatusModal ref={this.statusModal} />
            <h4>
              Unit Licenses
            </h4>
            <div style={{ minHeight: "75vh", maxHeight: "75vh" }}>
              <table className="fixedTable">
                <thead>
                  <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
                </thead>
                <tbody>
                  {
                    this.state.rows.map((item: UnitLicenseData, index: any) => (
                      (this.state.currentIndex === -1 || index < this.state.currentIndex) &&
                      <UnitLicenseTableRow key={item.uid} row={item} index={index} edit={this.edit} update={this.update} />
                    ))
                  }
                  {
                    this.state.currentIndex !== -1 ?
                      <EditableUnitLicense softwareUid={this.state.softwareUid} index={this.state.currentIndex} data={this.state.rows[this.state.currentIndex]} save={this.save} /> :
                      <TableAdditionRow length={9} marginLeft={"5%"} onClick={this.add} />
                  }
                  {
                    this.state.rows.map((item, index) => (
                      (this.state.currentIndex !== -1 && index > this.state.currentIndex) &&
                      <UnitLicenseTableRow key={item.uid} row={item} index={index} edit={this.edit} update={this.update} />
                    ))
                  }
                </tbody>
              </table>
              <input type="button" value="Generate ETL for All Softwares" className="standard-input" onClick={this.generateETL}  />
            </div>
          </>
          :
          <PropertyLicenseManagementTable licenseUID={this.state.currentUID} update={this.update} />
        }
      </div>
    )
  }
}
