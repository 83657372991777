import * as React from 'react';
import { ServerTicket, ServerTicketInformation } from '../TicketWork/TicketWorkManager.constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap';
import { CompanyNames } from '../TicketWork/TicketTableManager.constants';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface TicketInformationWidgetProps {
  uid: string;
}
interface TicketInformationWidgetState {
  uid: string;
  workNumber: string;
  email: string;
  name: string;
  phone: string;
  company: string;
  property: string;
  created: Date;
}

export default class TicketInformationWidget extends React.Component<TicketInformationWidgetProps, TicketInformationWidgetState> {

  constructor(props) {
    super(props);

    this.state = {
      uid: props.uid,
      workNumber: "",
      email: "",
      name: "",
      phone: "",
      company: "",
      property: "",
      created: new Date()
    }
  }

  async componentDidMount(): Promise<void> {
    const ticket: ServerTicketInformation = (await axios.get("api/tickets/" + this.state.uid + "/information")).data;
    this.setState({
      workNumber: ticket.workNumber,
      email: ticket.email,
      name: ticket.name,
      phone: ticket.phone,
      company: CompanyNames.filter(u => parseInt(u.value) === ticket.company)[0].label,
      property: ticket.property,
      created: ticket.created
    })
  }

  render(): JSX.Element {
    return (
      <>
        {RenderHeader("Ticket Information")}
        <Row>
          <Col>
            <h6>Work Number</h6>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <h6>{this.state.workNumber}</h6>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px"}} />
        <Row>
          <Col>
            <h6>Email</h6>
          </Col>
          <Col style={{ textAlign: "right" }} >
            <h6>{this.state.email}</h6>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h6>Name</h6>
          </Col>
          <Col style={{ textAlign: "right" }} >
            <h6>{this.state.name}</h6>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h6>Phone Number</h6>
          </Col>
          <Col style={{ textAlign: "right" }} >
            <h6>{this.state.phone}</h6>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h6>Company</h6>
          </Col>
          <Col style={{ textAlign: "right" }} >
            <h6>{this.state.company}</h6>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h6>Property</h6>
          </Col>
          <Col style={{ textAlign: "right" }} >
            <h6>{this.state.property}</h6>
          </Col>
        </Row>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <h6>Created</h6>
          </Col>
          <Col style={{ textAlign: "right" }} >
            <h6>{new Date(this.state.created).toLocaleDateString('en-CA')}</h6>
          </Col>
        </Row>
      </>
    )
  }
}