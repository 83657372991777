import * as React from 'react';
import { UnitStatusHistoryInterface, UnitStatusHistoryProps, UnitStatusHistoryState } from './UnitStatusHistory.constants';
import axios from 'axios';
import { UnitStatusList } from './PropertyUnitWidget.constants';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';

export default class UnitStatusHistory extends React.Component<UnitStatusHistoryProps, UnitStatusHistoryState> {

  constructor(props: UnitStatusHistoryProps) {
    super(props);
    this.state = {
      propertyUID: props.propertyUID,
      unitsHistory: [],
      headers: []
    }
  }

  async componentDidMount(): Promise<void> {
    const response = await axios.get('./api/unit-status-history/pull-by-property/' + this.state.propertyUID)
    const temp: Array<UnitStatusHistoryInterface> = []

    response.data.forEach((item) => {

      let end = item.endDate

      if (new Date(item.endDate) <= new Date("1950/01/01")) {
        end = Date.now();
      }

      const diff = Math.abs(new Date(item.startDate).getTime() - new Date(end).getTime());
      const dayDiff = Math.ceil(diff / (1000 * 3600 * 24))

      const _item: UnitStatusHistoryInterface = {
        unitName: item.unit.unitNumber,
        status: UnitStatusList.filter(u => u.value === item.status.toString())[0],
        startDate: new Date(item.startDate),
        endDate: new Date(item.endDate),
        dayDiff: dayDiff
      }

      temp.push(_item)
    })

    this.setHeaders();
    this.setState({ unitsHistory: temp })

  }

  setHeaders(): void {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "Name",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Status",
        additionalClasses: "thirty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "Start Date",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "End Date",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      },
      {
        columnName: "# of Days",
        additionalClasses: "twenty",
        setting: true,
        filter: {
        }
      }
    ]

    this.setState({
      headers: headers
    })
  }

  render(): JSX.Element {
    return (
      <>
        <h4>Unit Status History Table</h4>
        <table className="fixedTable">
          <thead>
            <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
          </thead>
          <tbody>
            {this.state.unitsHistory.map((unit: UnitStatusHistoryInterface) =>
              <tr key={unit.unitName}>
                <td className="fixedTableCellFirst">{unit?.unitName}</td>
                <td className="fixedTableCell" style={{ background: "#15405c" }}>{unit?.status?.label}</td>
                <td className="fixedTableCell" style={{ background: "#15405c" }}>{unit?.startDate.toLocaleDateString()}</td>
                <td className="fixedTableCell" style={{ background: "#15405c" }}>{unit?.endDate <= new Date(" 1950/01/01") ? "Active" : unit?.endDate.toLocaleDateString()}</td>
                <td className="fixedTableCell" style={{ background: "#15405c" }}>{unit?.dayDiff.toString()}</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    )
  }
}