import { faCog, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { getBearerToken } from '../../functions/authActions';
import { CreateGlobalAlert } from '../../functions/CreateGlobalAlerts';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import FilteredWithSettingsTableHeader from '../CoreComponents/CoreTableHeaders';
import { TableAdditionRow } from '../CoreComponents/CoreTableRows';
import { FilterAndSettingField } from '../CoreComponents/interfaces';

interface SoftwareManagementRow {
  uid: string;
  name: string,
  licenseCost: number,
  totalUnits: number,
  baseCost: number,
  billingType: SelectOptions;
  isAnnual: boolean;
  isRdIgnored: boolean;
}

interface SoftwareManagementTableState {
  headers: Array<FilterAndSettingField>;
  rows: Array<SoftwareManagementRow>;
  currentIndex: number;
  uid: string;
  update: (uid: string) => void;
}

interface SoftwareManagementTableProps {
  //update: (uid: string) => void;
}

interface SoftwareManagementRowProps {
  row: SoftwareManagementRow;
  index: number;
  editRow: (index: number) => void;
  update: (uid: string) => void;
}

interface EditableSoftwareManagementRowProps {
  row: SoftwareManagementRow;
  saveRow: (row: SoftwareManagementRow) => void;
}
interface SoftwareManagementRowState {
  row: SoftwareManagementRow;
  color: string;
}

class SoftwareManagementRowComponent extends React.Component<SoftwareManagementRowProps, SoftwareManagementRowState> {

  constructor(props) {
    super(props);
    this.state = {
      row: props.row,
      color: '#d7c7a7'
    }
  }

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst" onClick={() => window.location.assign("./old-software-billing?uid=" + this.state.row.uid)}
          style={{ color: this.state.color, cursor: "pointer" }}
          onMouseEnter={() => this.setState({ color: '#b89961' })} onMouseLeave={() => this.setState({ color: '#d7c7a7' })}
        >
          {this.state.row.name}
        </td>
        <td className="fixedTableCell" style={{ textAlign: "right" }}>
          0
        </td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faCog} onClick={() => this.props.editRow(this.props.index)} style={{ marginLeft: "30%" }} />
        </td>
      </tr>
    )
  }
}

class EditableSoftwareManagementRowComponent extends React.Component<EditableSoftwareManagementRowProps, SoftwareManagementRowState> {

  constructor(props) {
    super(props);
    this.state = {
      row: props.row,
      color: ""
    }
    this.updateSoftwareName = this.updateSoftwareName.bind(this);
    this.updateSoftwareInDatabase = this.updateSoftwareInDatabase.bind(this);
    this.updateUnitCount = this.updateUnitCount.bind(this);
    this.updateUnitCountInDatabase = this.updateUnitCountInDatabase.bind(this);
    this.updateBaseCost = this.updateBaseCost.bind(this);
    this.updateBaseCostInDatabase = this.updateBaseCostInDatabase.bind(this);
    this.updateBillingType = this.updateBillingType.bind(this);
    this.updateBillingTypeInDatabase = this.updateBillingTypeInDatabase.bind(this);
    this.updateIsAnnual = this.updateIsAnnual.bind(this);
    this.updateIsAnnualInDatabase = this.updateIsAnnualInDatabase.bind(this);
    this.updateRDIgnored = this.updateRDIgnored.bind(this);
    this.updateIsRdIgnoredInDatabase = this.updateIsRdIgnoredInDatabase.bind(this);
  }

  //#region Update Functions
  updateSoftwareName(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.row;
    row.name = event.target.value;
    this.setState({
      row: row
    });
  }

  check(): boolean {
    if (this.state.row.uid === "") {
      return true;
    }
    return false;
  }

  async updateSoftwareInDatabase(): Promise<void> {
    if (this.check()) { return; }
    const data = {
      UID: this.state.row.uid,
      Name: this.state.row.name
    }
    await axios.patch('./api/software-billing-setup/software', data).then(function (response) {
      CreateGlobalAlert("Software Name has been Updated");
    })
  }

  updateUnitCount(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.row;
    row.totalUnits = parseFloat(event.target.value);
    this.setState({
      row: row
    });
  }

  async updateUnitCountInDatabase(): Promise<void> {
    if (this.check()) { return; }
    const data = {
      UID: this.state.row.uid,
      UnitCount: this.state.row.totalUnits
    }
    await axios.patch('./api/software-billing-setup/unit-count', data).then(function (response) {
      CreateGlobalAlert("Unit Count has been Updated");
    })
  }

  updateBaseCost(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.row;
    row.baseCost = parseFloat(event.target.value);
    this.setState({
      row: row
    });
  }

  async updateBaseCostInDatabase(): Promise<void> {
    if (this.check()) { return; }
    const data = {
      UID: this.state.row.uid,
      BaseCount: this.state.row.baseCost
    }
    await axios.patch("./api/software-billing-setup/base-cost", data).then(function (response) {
      CreateGlobalAlert("Base Cost has been Updated");
    });
  }

  updateBillingType(event: SelectOptions | null): void {
    if (event === null) { return; }
    let row = this.state.row;
    row.billingType = event;
    this.setState({
      row: row
    });
  }

  async updateBillingTypeInDatabase(): Promise<void> {
    if (this.check()) { return; }
    const data = {
      UID: this.state.row.uid,
      Billing: this.state.row.billingType.value
    }
    await axios.patch("./api/software-billing-setup/billing", data).then(function (response) {
      CreateGlobalAlert("Billing Type has been updated.")
    });
  }

  updateIsAnnual(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.row;
    row.isAnnual = event.target.checked;
    this.setState({
      row: row
    });
  }

  async updateIsAnnualInDatabase(): Promise<void> {
    if (this.check()) { return; }
    const data = {
      UID: this.state.row.uid,
      IsMonthly: this.state.row.isAnnual
    }
    await axios.patch("./api/software-billing-setup/is-monthly", data).then(function (response) {
      CreateGlobalAlert("Is Annual or Monthly has been updated.")
    });
  }

  updateRDIgnored(event: React.ChangeEvent<HTMLInputElement>): void {
    let row = this.state.row;
    row.isRdIgnored = event.target.checked;
    this.setState({
      row: row
    });
  }

  async updateIsRdIgnoredInDatabase(): Promise<void> {
    if (this.check()) { return; }
    const data = {
      UID: this.state.row.uid,
      IsRDIgnored: this.state.row.isRdIgnored
    }
    await axios.patch("./api/software-billing-setup/is-rd-ignored", data).then(function (response) {
      CreateGlobalAlert("RD Ignored has been changed");
    });
  }
  //#endregion

  render(): JSX.Element {
    return (
      <tr>
        <td className="fixedTableCellFirst">
          <input type="text" className="standard-input"
            value={this.state.row.name}
            onChange={this.updateSoftwareName}
            onBlur={this.updateSoftwareInDatabase}
          />
        </td>
        <td className="fixedTableCell"></td>
        <td className="fixedTableCell">
          <FontAwesomeIcon icon={faSave} onClick={() => this.props.saveRow(this.state.row)} style={{ marginLeft: "30%" }} />
        </td>
      </tr>
    )
  }
}

export class SoftwareManagementTable extends React.Component<SoftwareManagementTableProps, SoftwareManagementTableState> {

  constructor(props) {
    super(props);
    this.state = {
      headers: this.generateHeaders(),
      rows: [],
      currentIndex: -2,
      uid: "",
      update: props.update
    }

    this.addRow = this.addRow.bind(this);
    this.editRow = this.editRow.bind(this);
    this.saveRow = this.saveRow.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    await axios.get('./api/software-billing-setup').then((response) => {
      let data = response.data;
      let rows: Array<SoftwareManagementRow> = [];
      data.forEach(function (item: any) {
        rows.push({
          uid: item.uid,
          name: item.softwareCompany,
          licenseCost: item.licenseCost,
          totalUnits: item.totalUnits,
          baseCost: item.baseCost,
          billingType: { label: "", value: ""},
          isAnnual: item.isMonthlyBilling,
          isRdIgnored: item.isRDIgnored
        })
      })
      this.setState({
        rows: rows
      })
    })
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Software Company",
        filter: {},
        setting: true
      },
      {
        columnName: "Estimated Cost (Yr)",
        filter: {},
        setting: true
      },
      {
        columnName: "",
        filter: {},
        additionalClasses: "icon",
        setting: true
      }
    ]
  }

  addRow(): void {
    let rows = this.state.rows;
    rows.push({
      uid: "",
      name: "",
      licenseCost: 0,
      totalUnits: 0,
      baseCost: 0,
      billingType: { label: "Select Billing Type", value: "" },
      isAnnual: false,
      isRdIgnored: false
    })
    this.setState({
      rows: rows,
      currentIndex: rows.length - 1
    })
  }

  editRow(index: number): void {
    this.setState({
      currentIndex: index
    })
  }

  async saveRow(row: SoftwareManagementRow): Promise<void> {
    if (row.uid === "") {
      const data = {
        Name: this.state.rows[this.state.currentIndex].name,
        UnitCost: this.state.rows[this.state.currentIndex].licenseCost,
        UnitCount: this.state.rows[this.state.currentIndex].totalUnits,
        BaseCost: this.state.rows[this.state.currentIndex].baseCost,
        Billing: this.state.rows[this.state.currentIndex].billingType.value,
        IsAnnual: this.state.rows[this.state.currentIndex].isAnnual,
        IsRDIgnored: this.state.rows[this.state.currentIndex].isRdIgnored
      }
      await axios.post('./api/software-billing-setup', data).then(function (response) {
        CreateGlobalAlert("Software has been added to the database", 2000);
      });
    }
    this.setState({
      currentIndex: -2
    })
  }

  render(): JSX.Element {
    return (
      <>
        <div>
          <h5 style={{ width: "100%", textAlign: "center" }}>Software Billing Table</h5>
          <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item: SoftwareManagementRow, index: number) => (
                  (this.state.currentIndex === -2 || index < this.state.currentIndex) &&
                  <SoftwareManagementRowComponent key={item.uid} row={item} editRow={this.editRow} index={index} update={this.state.update} />
                ))
              }
              {this.state.currentIndex === this.state.rows.length-1 || this.state.currentIndex !== -2 ?
                <EditableSoftwareManagementRowComponent row={this.state.rows[this.state.currentIndex]} saveRow={this.saveRow} /> :
                <TableAdditionRow length={3} marginLeft={"30%"} onClick={this.addRow} />
              }
              {
                this.state.rows.map((item: SoftwareManagementRow, index: number) => (
                  (this.state.currentIndex !== -2 && index > this.state.currentIndex) &&
                  <SoftwareManagementRowComponent key={item.uid} row={item} editRow={this.editRow} index={index} update={this.state.update} />
                ))
              }
            </tbody>
          </table>
        </div>
      </>
    )
  }
}