import axios from "axios";
import * as React from "react"
import { Col, Row } from "reactstrap";
import { getUserID } from "../../../functions/authActions";
import { numberWithCommas } from "../../../functions/numberFunctions";
import { WarningModal } from "../../CoreComponents/Modals";

interface SubcontractingFinanceSnapshotData {
  name: string;
  uid: string;
  overUnder: number;
}

interface WorkflowReadyForReviewState {
  projectData: Array<SubcontractingFinanceSnapshotData>;
  isPresident: boolean;
}

export class FinanceSnapshotWidget extends React.Component<{ isPresident: boolean }, WorkflowReadyForReviewState> {

  warningModal = React.createRef<WarningModal>();
  timer: NodeJS.Timer = null;

  constructor(props) {
    super(props);
    this.state = {
      isPresident: props.isPresident,
      projectData: [
        {
          name: "test_project_1",
          uid: "",
          overUnder: -18.9
        },
        {
          name: "test_project_2",
          uid: "",
          overUnder: 14.6
        },
        {
          name: "test_project_3",
          uid: "",
          overUnder: 18.6
        }
      ]
    }
  }

  async componentDidMount(): Promise<void> {

  }

  render(): JSX.Element {
    return (
      <div>
        <div style={{ maxHeight: "38vh", minHeight: "38vh", overflowY: "auto", overflowX: "hidden", borderRadius: "10% 10% 0 0", backgroundColor: "#4C4A42" }}>
          <h4 style={{ width: "100%", textAlign: "center", fontSize: "14px", marginTop: "1vh" }}>Subcontracting Project Finance Snapshot</h4>
          <WarningModal ref={this.warningModal} />
          {
            this.state.projectData.map((item, idx) => (
              <div key={idx}>
                <hr style={{ height: "4px", backgroundColor: "#ddd" }} />
                <div style={{ width: "100%" }}>
                  <Row>
                    <Col>
                      <h5 style={{ width: "96%", marginLeft: "2%", fontSize: "12px" }}>{item.name}</h5>
                    </Col>
                    <Col>
                      <h5 style={{ width: "96%", marginLeft: "2%", fontSize: "12px", textAlign: "center" }}>0%</h5>
                    </Col>
                    <Col>
                      <h6 style={{ width: "100%", textAlign: "right", marginRight: "3%", fontSize: "12px" }}>{numberWithCommas(item.overUnder, 0)}%</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <progress className="over-under-under" value={25 + (item.overUnder >= 0 ? 0 : item.overUnder)} max={25}
                        style={{ width: "49%", marginLeft: "1%", marginTop: "0%", marginBottom: "0%" }}>
                      </progress>
                      <progress className="over-under-over" value={item.overUnder <= 0 ? 0 : item.overUnder} max={25}
                        style={{ width: "49%", marginRight: "1%", marginTop: "0%", marginBottom: "0%" }}>
                      </progress>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h6 style={{ width: "100%", marginLeft: "1%", fontSize: "12px" }}>Project is {item.overUnder}% {item.overUnder <= 0 ? "Under" : "Over"} Budget</h6>
                    </Col>
                  </Row>
                </div>
              </div>

            ))
          }
        </div>
      </div>
    )
  }
}