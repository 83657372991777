import * as React from 'react';
import axios from 'axios';
import { StatusModal, WarningModal } from '../../CoreComponents/Modals';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { Col, Row } from 'reactstrap';
import VerizonBillingUploadModal, { VerizonBillingUploadData } from './VerizonBillingProcessing.modals';
import { numberWithCommas } from '../../../functions/numberFunctions';
import { DownloadNamedFile } from '../../../functions/documentTools';
import { PullSoftwareBillingRuns } from '../SoftwareBillingFunctions';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface VerizonBillingProcessingState {
  headers: Array<FilterAndSettingField>;
  data: Array<any>;
}



export default class VerizonBillingProcessing extends React.Component<{}, VerizonBillingProcessingState> {

  statusModal: React.RefObject<StatusModal> = React.createRef<StatusModal>();
  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  uploadModal: React.RefObject<VerizonBillingUploadModal> = React.createRef<VerizonBillingUploadModal>();

  constructor(props) {
    super(props);
    this.state = {
      headers: [],
      data: []
    }
    this.callback = this.callback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
    let data = await PullSoftwareBillingRuns(this.warningModal, "Verizon");
    this.setState({ data: data });
  }

  async generateHeaders(): Promise<void> {
    let headers: Array<FilterAndSettingField> = [];
    headers.push({ columnName: "Bill Date", setting: true, filter: {} });
    headers.push({ columnName: "Total Cost", setting: true, filter: {} });
    headers.push({ columnName: "Output", setting: true, filter: {} });
    this.setState({ headers: headers });
  }

  async callback(data: VerizonBillingUploadData): Promise<void> {
    let key = await axios.get('./api/status-manager');
    let formInfo = new FormData();
    formInfo.append('BillingDate', data.billingDate.toISOString());
    formInfo.append('CSVData', data.csvFile);
    formInfo.append('StatusKey', key.data);
    formInfo.append('AccountCharge', data.accountCharge.toFixed(2));
    this.statusModal.current.display("Processing Verizon Bill", "Currently Processing the Verizon bill into uploads. This may take a minute.", key.data);
    let response = await axios.post('./api/software-billing-tracking/verizon', formInfo, {
      responseType: 'blob',
      validateStatus: () => true,
      timeout: 240000,
      timeoutErrorMessage: "Generating Verizon Files took longer than 4 minutes, please contact the tech department"
    });
    this.statusModal.current.hide();
    if (response.status !== 200) {
      this.warningModal.current.show("There was an error processing the verizon bill. Please contact the tech team so that they can investigate the concern", "Error.")
    }
    else {
      await DownloadNamedFile(response);
    }
  }

  async downloadOutputFile(uid: string): Promise<void> {
    let response = await axios.get('./api/saved-files/' + uid + "/pull");
    if (response.status === 200) {
      await DownloadNamedFile(response);
    }
    else {
      this.warningModal.current.show("Error while attempting to download the request files. Please contact the tech team", "Error.");
    }
  }

  render(): JSX.Element {
    return (
      <>
        <StatusModal ref={this.statusModal} />
        <WarningModal ref={this.warningModal} />
        <VerizonBillingUploadModal ref={this.uploadModal} callback={this.callback} />
        {RenderHeader("Verizon Billing Processing")}
        <div style={{ height: "25vh", overflowY: "auto" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.data.map((item) =>
                (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{new Date(item.billingDate).toLocaleDateString('en-CA')}</td>
                    <td className="fixedTableCell" style={{ textAlign: 'right' }}>${numberWithCommas(item.totalPrice)}</td>
                    <td className="fixedTableCell">
                      <input type="button" value="Download" className="standard-input" onClick={(e) => this.downloadOutputFile(item.outputFileUID)} />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
        <hr style={{ height: "3px", padding: "0px", marginTop: "5px", marginBottom: "8px" }} />
        <Row>
          <Col>
            <input type="button" value="Run Verizon Billing Processing" className="standard-input" onClick={() => this.uploadModal.current.show()} />
          </Col>
          <Col>
            <input type="button" value="View Documentation" className="standard-input" onClick={() => window.location.assign('./knowledge-base-home?search=Verizon%20Billing')} />
          </Col>
        </Row>
      </>
    )
  }
}