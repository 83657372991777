import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { TableAdditionRow } from '../../CoreComponents/CoreTableRows';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { WarningModal } from '../../CoreComponents/Modals';
import { MCIActiveCourseData, MCIActiveCourseTableProps, MCIActiveCourseTableState } from '../AdminPanel/ActiveCourseTable.constants';
import { ActiveCourseCreationModal } from '../AdminPanel/ActiveCourseManagerComponents/ActiveCourseCreationModal';


export class ActiveCourseWidget extends React.Component<MCIActiveCourseTableProps, MCIActiveCourseTableState> {

  warningModal: React.RefObject<WarningModal> = React.createRef<WarningModal>();
  activeCourseCreationModal: React.RefObject<ActiveCourseCreationModal> = React.createRef<ActiveCourseCreationModal>();

  constructor(props: MCIActiveCourseTableProps) {
    super(props);
    this.state = {
      rows: [],
      headers: this.generateHeaders()
    }
    this.generateHeaders = this.generateHeaders.bind(this);
    this.creationCallback = this.creationCallback.bind(this);
  }

  async componentDidMount(): Promise<void> {
    let response = await axios.get('./api/active-courses/table-data');
    if (response.status === 200) {
      let active: Array<MCIActiveCourseData> = [];
      response.data.forEach((item) => {
        active.push({
          uid: item.uid,
          name: item.name,
          instructor: item.instructor,
          startDate: new Date(item.startDate),
          studentCount: item.studentCount,
          courseCost: item.courseCost
        });
      })
      this.setState({
        rows: response.data
      })
    }
    else {
      this.warningModal.current.show("Unable to load active courses from the database. Please submit a tech team ticket so that the team is aware of the problem.", "Error.");
    }
  }

  generateHeaders(): Array<FilterAndSettingField> {
    return [
      {
        columnName: "Course Name",
        setting: true,
        filter: {}
      },
      {
        columnName: "Instructor",
        setting: true,
        filter: {},
        additionalClasses: "fifteen"
      },
      {
        columnName: "Start Date",
        setting: true,
        filter: {},
        additionalClasses: "fifteen"
      },
      {
        columnName: "Students",
        setting: true,
        filter: {},
        additionalClasses: "fifteen"
      },
      {
        columnName: "Cost",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      },
      {
        columnName: "Income",
        setting: true,
        filter: {},
        additionalClasses: "ten"
      },
      {
        columnName: "",
        setting: true,
        additionalClasses: "icon",
        filter: {}
      }
    ]
  }

  edit(uid: string): void {
    window.location.assign('./active-course-manager?uid=' + uid);
  }

  creationCallback(uid: string, successful: boolean) {
    this.activeCourseCreationModal.current.close();
    if (successful) {
      this.warningModal.current.show("Course has been created successfully. You will be redirected to the management page on closing this modal", "Course Created", () => window.location.assign('/active-course-manager?uid=' + uid));
    }
    else {
      this.warningModal.current.show("There has been an error creating the course. Please submit a tech ticket so that team can investigate.", "Error.", () => window.location.reload());
    }
  }

  render(): JSX.Element {
    return (
      <>
        <div style={{ maxHeight: "31vh" }}>
          <WarningModal ref={this.warningModal} />
          <ActiveCourseCreationModal ref={this.activeCourseCreationModal} creationCallback={this.creationCallback} />
          <h4 style={{ width: "100%", textAlign: "center" }}>Active Courses</h4>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.rows.map((item) => (
                  <tr key={item.uid} >
                    <td className="fixedTableCellFirst">{item.name}</td>
                    <td className="fixedTableCell">{item.instructor}</td>
                    <td className="fixedTableCell">{new Date(item.startDate).toISOString().split('T')[0]}</td>
                    <td className="fixedTableCell">{item.studentCount}</td>
                    <td className="fixedTableCell" style={{ textAlign: "right" }} >${item.courseCost}</td>
                    <td className="fixedTableCell" style={{ textAlign: "right" }} >${item.courseCost * item.studentCount}</td>
                    <td className="fixedTableCell">
                      <FontAwesomeIcon icon={faCog} onClick={() => this.edit(item.uid)}
                        style={{ marginLeft: "25%" }} className="fa-icon"
                      />
                    </td>
                  </tr>
                ))
              }
              <TableAdditionRow length={7} marginLeft="25%" onClick={() => this.activeCourseCreationModal.current.show()} />
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

