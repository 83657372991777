import * as React from 'react';
import { DashboardManagementModal } from '../CoreComponents/Modals/DashboardManagementModal';
import { Col, Row } from 'reactstrap';
import { DashboardOrganizer } from '../../functions/WidgetDashboardOrganizer';
import { SelectOptions } from '../../interfaces/CoreInterfaces';
import TicketNotesManager from './TicketNotesManager';
import { GetSettings } from '../../functions/StateManagement/StateStorageFunctions';
import TicketInformationWidget from './TicketWidgets/TicketInformationWidget';
import TicketAssignmentInformationWidget from './TicketWidgets/TicketAssignmentInformationWidget';
import TicketPhotoGallery from './TicketWidgets/TicketPhotoGallery';
import TicketSubmissionEmployeeCategories from './TicketWidgets/TicketSubmissionEmployeeCategories';
import TicketPrimaryCategoriesWidget from './TicketWidgets/TicketPrimaryCategoriesWidget';
import TicketSecondaryCategoriesWidget from './TicketWidgets/TicketSecondaryCategoriesWidget';
import ProjectTaskAnalysisWidget from './TicketWidgets/ProjectTaskAnalysisWidget';
import axios from 'axios';
import { getBearerToken } from '../../functions/authActions';
import TicketCategoryDocumentationMap from './TicketWidgets/TicketCategoryDocumentationMap';
import { RenderSettings } from '../../interfaces/WidgetSettingsInterfaces';
import TicketChangeLogWidget from './TicketWidgets/TicketChangeLog';
import TicketSummaryWidget from './TicketWidgets/TicketSummaryWidget';
import TicketSummaryOnlyWidget from './TicketWidgets/TicketSummaryOnlyWidget';
import TicketEmailManagerWidget from './TicketWidgets/TicketEmailManagerWidget';

interface TicketWorkDashboardState {
  uid: string;
  renderSettings: RenderSettings;
  tabList: { [key: number]: JSX.Element };
  widgetList: Array<SelectOptions>;
  loaded: boolean;
  settingsString: string;
}

export default class TicketWorkDashboard extends React.Component<{}, TicketWorkDashboardState> {

  widgetSettingsModalRef: React.RefObject<DashboardManagementModal> = React.createRef<DashboardManagementModal>();

  constructor(props: {}) {
    super(props);
    let params = new URLSearchParams(window.location.search);
    let uid = params.get('uid') ?? "Invalid UID, This will cause issues";
    let settings: RenderSettings = {
      rows: []
    }
    this.state = {
      uid: uid,
      tabList: {},
      renderSettings: settings,
      widgetList: [],
      loaded: false,
      settingsString: ""
    }
    this.updateUI = this.updateUI.bind(this);
  }

  async componentDidMount(): Promise<void> {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + getBearerToken();
    let settings: RenderSettings;
    let settingsString: string = "";
    let category = await axios.get('./api/tickets/category/' + this.state.uid);
    let data: string = category.data;
    await this.prepareDashboard(data);
    if (data.toLocaleLowerCase().includes('projects')) {
      settingsString = "projectWorkManager";
      settings = GetSettings<RenderSettings>(settingsString);
      let showModal: boolean = settings === null;
      if (showModal) {
        this.widgetSettingsModalRef.current.show(settingsString, this.state.widgetList, this.updateUI);
      }
    }
    else if (data.toLocaleLowerCase().includes('equipment request')) {
      settingsString = "equipmentWorkManager";
      settings = GetSettings<RenderSettings>(settingsString);
      let showModal: boolean = settings === null;
      if (showModal) {
        this.widgetSettingsModalRef.current.show(settingsString, this.state.widgetList, this.updateUI);
      }
    }
    else {
      settingsString = "ticketWorkManager"
      settings = GetSettings<RenderSettings>(settingsString);
      let showModal: boolean = settings === null;
      if (showModal) {
        this.widgetSettingsModalRef.current.show(settingsString, this.state.widgetList, this.updateUI);
      }
    }
    this.setState({ settingsString: settingsString, renderSettings: settings, loaded: true });
  }

  async prepareDashboard(category: string): Promise<void> {
    let tabList: { [key: number]: JSX.Element } = {
      0: <TicketNotesManager uid={this.state.uid} />,
      1: <TicketInformationWidget uid={this.state.uid} />,
      2: <TicketAssignmentInformationWidget uid={this.state.uid} />,
      3: <TicketSummaryWidget uid={this.state.uid} />,
      11: <TicketSummaryOnlyWidget uid={this.state.uid} />,
      9: <TicketCategoryDocumentationMap uid={this.state.uid} />,
      10: <TicketChangeLogWidget uid={this.state.uid} />,
    }
    let widgetList: Array<SelectOptions> = [
      { label: "Ticket Information", value: "1" },
      { label: "Ticket Assignment Info", value: "2" },
      { label: "Ticket Summaries", value: "3" },
      { label: "Ticket Summary Only", value: "11"},
      { label: "Ticket Change Log", value: "10" },
      
    ];
    if (category.toLocaleLowerCase().includes('projects')) {
      tabList[0] = <TicketNotesManager uid={this.state.uid} />;
      tabList[6] = <TicketPrimaryCategoriesWidget />
      tabList[7] = <TicketSecondaryCategoriesWidget />
      tabList[8] = <ProjectTaskAnalysisWidget uid={this.state.uid} />
      widgetList.push({ label: "Ticket Notes Manager", value: "0" });
      widgetList.push({ label: "Ticket Primary Categories", value: "6" });
      widgetList.push({ label: "Ticket Secondary Categories", value: "7" });
      widgetList.push({ label: "Project Analysis Widget", value: "8" })
    }
    else if (category.toLocaleLowerCase().includes("equipment request")) {

    }
    else {
      tabList[4] = <TicketPhotoGallery uid={this.state.uid} />;
      tabList[5] = <TicketSubmissionEmployeeCategories uid={this.state.uid} />;
      tabList[6] = <TicketPrimaryCategoriesWidget />;
      tabList[7] = <TicketSecondaryCategoriesWidget />;
      tabList[12] = <TicketEmailManagerWidget uid={this.state.uid} />

      widgetList.push({ label: "Ticket Notes Manager", value: "0" });
      widgetList.push({ label: "Ticket Photos", value: "4" });
      widgetList.push({ label: "Employee Categories", value: "5" });
      widgetList.push({ label: "Ticket Primary Categories", value: "6" });
      widgetList.push({ label: "Ticket Secondary Categories", value: "7" });
      widgetList.push({ label: "Ticket Email Thread", value: "12"})
    }
    this.setState({ tabList: tabList, widgetList: widgetList });
  }

  updateUI(settings: RenderSettings): void {
    this.setState({ renderSettings: settings })
  }

  render(): JSX.Element {
    return (
      <>
        <DashboardManagementModal ref={this.widgetSettingsModalRef} />
        <Row>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", width: "70vw", paddingTop: "1vh" }}>
              <h4 style={{ marginLeft: "9%", width: "100%", textAlign: "center" }}>Task Management Dashboard</h4>
            </div>
          </Col>
          <Col>
            <div style={{ display: "block", height: "6vh", float: "left", marginLeft: "7%" }}>
              <input type="button" className="standardInput" value="Edit Widgets" onClick={() => this.widgetSettingsModalRef.current.show(this.state.settingsString, this.state.widgetList, this.updateUI)} />
            </div>
          </Col>
          <div style={{ float: "none" }}></div>
        </Row>
        {this.state.loaded && this.state.renderSettings !== null && DashboardOrganizer(this.state.tabList, this.state.renderSettings)}
      </>
    )
  }
}