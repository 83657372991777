import axios from 'axios';
import * as React from 'react';
import FilteredWithSettingsTableHeader from '../../CoreComponents/CoreTableHeaders';
import { FilterAndSettingField } from '../../CoreComponents/interfaces';
import { RenderHeader } from '../../../functions/SkeletonCodeFunctions/WidgetSkeletonCode';

interface ChangeLogData {
  uid: string;
  time: Date;
  modifier: string;
  description: string;
}

interface TicketNotesManagerState {
  uid: string;
  headers: Array<FilterAndSettingField>;
  changeLogs: Array<ChangeLogData>;
}
export default class TicketChangeLogWidget extends React.Component<{ uid: string }, TicketNotesManagerState> {

  interval: NodeJS.Timeout;

  constructor(props) {
    super(props);
    this.state = {
      uid: props.uid,
      headers: [],
      changeLogs: []
    }
  }

  async pullChangeLogs(): Promise<void> {
    let response = await axios.get('./api/ticket-change-tracking/' + this.props.uid);
    let rows: Array<ChangeLogData> = [];
    response.data.forEach((item) => {
      rows.push({
        uid: item.uid,
        description: item.description,
        modifier: item.modifier,
        time: new Date(item.time,)
      })
    });
    this.setState({ changeLogs: rows });
  }

  async componentDidMount(): Promise<void> {
    await this.generateHeaders();
    await this.pullChangeLogs();
    this.interval = setInterval(async () => await this.pullChangeLogs(), 1500);
  }

  async componentWillUnmount(): Promise<void> {
    clearInterval(this.interval);
  }

  async generateHeaders(): Promise<void> {
    const headers: Array<FilterAndSettingField> = [
      {
        columnName: "User",
        filter: {},
        setting: true,
        additionalClasses: "fifteen"
      },
      {
        columnName: "Time",
        filter: {},
        setting: true,
        additionalClasses: "twenty"
      },
      {
        columnName: "Description",
        filter: {},
        setting: true
      }
    ]
    this.setState({ headers: headers });
  }

  render(): JSX.Element {
    return (
      <>
        {RenderHeader("Change Logs")}
        <div style={{ height: "26vh", overflowY: "auto", overflowX: "hidden" }}>
          <table className="fixedTable">
            <thead>
              <FilteredWithSettingsTableHeader columns={this.state.headers} icons={0} />
            </thead>
            <tbody>
              {
                this.state.changeLogs.map((item) => (
                  <tr key={item.uid}>
                    <td className="fixedTableCellFirst">{item.modifier}</td>
                    <td className="fixedTableCell">{item.time.toLocaleString('en-CA')}</td>
                    <td className="fixedTableCell">{item.description}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </>
    )
  }
}
